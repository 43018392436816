import React, { FC } from "react"

import Layout from "../components/Layout"
import SEO from "../components/SEO"
import CookiesSnackbar from "../components/CookiesSnackbar"

const NotFoundPage: FC = () => (
    <Layout>
        <SEO title="404: Not found" />
        <h1>NOT FOUND</h1>
        <p>You just hit a route that doesn&#39;t exist</p>
        <CookiesSnackbar />
    </Layout>
)

export default NotFoundPage
